import { layoutXxl } from '@utils/constants'
import styled, { css } from 'styled-components'
import { MenuTabItemProps, MenuTabPaneProps, MenuTabProps } from './interface'

export const StyledMenuTabPane = styled.li<MenuTabPaneProps>`
  list-style: none;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSize.sm};
  padding: ${({ theme }) => `${theme.space.sm} ${theme.space.md} ${theme.space.xs} ${theme.space.sm}`};
  background-color: ${({ theme }) => theme.palette.blue10};
  min-width: ${layoutXxl};
  margin-left: 2px;
  border-bottom: 2px solid ${({ theme }) => theme.palette.blue20};
  vertical-align: bottom;
  text-align: center;
  color: ${({ theme }) => theme.palette.blue70};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
    min-width: auto;
    padding-right: ${({ theme }) => theme.space.xs};
  }

  a {
    text-decoration: none;
    display: block;
    padding-left: ${({ theme }) => theme.space.xxs};
    transition: ${({ theme }) => theme.animation.transitionSlow};
    transition-duration: ${({ theme }) => theme.animation.medium};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    color: ${({ theme }) => theme.palette.blue70};

    &:hover {
      color: ${({ theme }) => theme.colors.text01};

      svg {
        color: ${({ theme }) => theme.palette.charcoal};
      }
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding-left: ${({ theme }) => theme.space.xxs};
    }
  }

  svg {
    margin-right: ${({ theme }) => theme.space.xs};
    color: ${({ theme }) => theme.palette.blue70};
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      a, svg {
        color: ${theme.palette.gray100};
      }

      &:hover {
        cursor: not-allowed;

        a {
          pointer-events: none;
      }
    `}
`

export const StyledMenuTabItem = styled.li<MenuTabItemProps>`
  list-style: none;
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSize.sm};
  padding: ${({ theme }) => `${theme.space.xxxs} ${theme.space.xxxs} ${theme.space.xs}`};
  min-width: ${layoutXxl};

  ${({ smallWidth }) =>
    smallWidth &&
    css`
      min-width: max-content;
      padding-right: ${({ theme }) => theme.space.lg};
      margin-right: ${({ theme }) => theme.space.md};
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
    min-width: auto;
    padding-right: ${({ theme }) => theme.space.xs};
  }

  a {
    color: ${({ theme }) => theme.palette.charcoal};
    text-decoration: none;
    display: block;
    padding-left: ${({ theme }) => theme.space.xxs};
    transition: ${({ theme }) => theme.animation.transitionSlow};
    transition-duration: ${({ theme }) => theme.animation.medium};
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.text01};
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding-left: ${({ theme }) => theme.space.xxs};
    }
  }
`

export const StyledMenuTab = styled.ul<MenuTabProps>`
  list-style: none;
  display: table;
  padding-inline-start: 0px;
  width: 100%;
  margin: 0px;

  ${StyledMenuTabItem}:nth-child(${({ activeTab }) => activeTab}) {
    border-bottom: ${({ theme }) => `4px solid ${theme.palette.purple100}`};
    font-weight: ${({ theme }) => theme.fontWeight.bold};

    &:hover {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    }
  }

  ${StyledMenuTabPane}:nth-child(${({ activeTab }) => activeTab}) {
    padding: ${({ theme }) => `${theme.space.md} ${theme.space.md} ${theme.space.sm} ${theme.space.xs}`};
    border-bottom: ${({ theme }) => `4px solid ${theme.palette.purple100}`};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    background-color: ${({ theme }) => theme.palette.purple30};
    color: ${({ theme }) => theme.palette.charcoal};
    margin-left: 0px;

    &:hover {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    }

    a {
      color: ${({ theme }) => theme.palette.charcoal};

      svg {
        color: ${({ theme }) => theme.palette.charcoal};
      }
    }
  }
`

export const HR = styled.hr`
  margin: -2px 0 0 0;
  border: none;
  border-bottom: ${({ theme }) => theme.space.xxxs} solid ${({ theme }) => theme.palette.blue20};
`
