import * as React from 'react'

import { MenuTabItemProps } from './interface'
import { StyledMenuTabItem } from './style'

const MenuTabItem: React.FC<MenuTabItemProps> = ({ children, smallWidth }) => (
  <StyledMenuTabItem smallWidth={smallWidth}>{children}</StyledMenuTabItem>
)

export default MenuTabItem
