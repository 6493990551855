import * as React from 'react'

import { MenuTabProps } from './interface'
import { HR, StyledMenuTab } from './style'

const MenuTab: React.FC<MenuTabProps> = ({ children, activeTab = 1 }) => (
  <>
    <StyledMenuTab activeTab={activeTab}>{children}</StyledMenuTab>
    <HR />
  </>
)

export default MenuTab
