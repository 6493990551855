import * as React from 'react'
import { MenuTabPaneProps } from './interface'

import { StyledMenuTabPane } from './style'

const MenuTabPane: React.FC<MenuTabPaneProps> = ({ children, disabled = false }) => (
  <StyledMenuTabPane disabled={disabled}>{children}</StyledMenuTabPane>
)

export default MenuTabPane
